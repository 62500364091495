

































































import { Component, Vue } from "vue-property-decorator";
import { AdminRouter } from "@/utils/routePathContsant";
import pagination from "@/components/UIComponents/Pagination.vue";
import { RouteRoad } from "@/store/models/meta";
import metaStore from "@/store/modules/meta";


@Component({
  components: {
    pagination,
  }
})
export default class RouteRoadListView extends Vue {
  qUsers: RouteRoad[] = [];

  async created(){
    await metaStore.getRouteRoad();
  }

  get AdminRouter() {
    return AdminRouter;
  }

  get routeRoadList() {
    return metaStore.routeRoadList;
  }
} 
